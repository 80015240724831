@import url("https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&display=swap");

.header-container {
  position: relative;
  z-index: 100;
}

.parent {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    margin-left: -40px;
    align-items: center;
    justify-content: space-between;
  }

  .logo {
    grid-column: 2 / span 2;
    min-width: 150px;
  }

  .nav {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    grid-column: 6 / span 6;
  }

  .nav li {
    list-style: none;
  }

  .nav li a {
    text-decoration: none;
    color: black;
  }

  .nav li a:hover {
    color: gray;
  }

  .toggle-button {
    display: none;
    grid-column: 9 / span 1;
  }

  .dropdown-links {
    display: none;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 100%; 
    right: 0; 
    background-color: #fff; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  }

  .dropdown-links li {
    margin: 10px 0;
  }

  .links {
    margin-block: 0;
    margin-inline: 0;
    font-family: "Karla", sans-serif;
    line-height: 1;
    font-weight: 500px;
  }

  @media (max-width: 1150px) {
    /* .toggle-button {
      display: flex;
      grid-column: 10;
    }

    .nav {
      position: absolute;
      display: none;
      flex-direction: column;
      grid-column: 9 / span 3;
      z-index: 100;
    }

    .nav.show {
      display: flex;
    }

    .logo {
      grid-column: 2 / span 1;
    } */
  }
