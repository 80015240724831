@import url("https://fonts.googleapis.com/css2?family=Markazi+Text:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&display=swap");

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: "Karla", sans-serif;
	color: var(--secondary-4);
	transition: all 0.3s ease-in-out;
}

.h1 {
	margin-block: 0;
	margin-inline: 0;
	font-family: "Karla", sans-serif;
	line-height: 1;
	font-weight: bold;
}
.h2 {
	margin-block: 0;
	margin-inline: 0;
	font-family: "Karla", sans-serif;
	line-height: 1;
	font-weight: 500px;
}

.h1 {
	font-size: 3.5rem;
}

.h2 {
	font-size: 2.5rem;
}
